// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "G_gy";
export var multiVendorBusinessesSection = "G_gr";
export var multiVendorClinetsSection = "G_gF";
export var multiVendorDevExpertiseSection = "G_gB";
export var multiVendorDiscoverWorksSection = "G_gx";
export var multiVendorFeaturesSection = "G_gq";
export var multiVendorIdeaToLifeSection = "G_gw";
export var multiVendorIndustriesSection = "G_gs";
export var multiVendorOurAchievementsSection = "G_gD";
export var multiVendorProcessSection = "G_gt";
export var multiVendorProjLogoSection = "G_gz";
export var multiVendorServicesSection = "G_gp";
export var multiVendorSuccessStoriesSection = "G_gC";
export var multiVendorTechStackSection = "G_gv";